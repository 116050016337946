import { uploadFile } from "Services/UploadManager";
import generateRoute from "Services/Router";
import client from "Utils/client";
const UPLOAD_URL = '/_uploader/document_templates/upload';
export var DocumentTemplateServices;
(function (DocumentTemplateServices) {
    DocumentTemplateServices.uploadLogo = (file) => {
        return uploadFile(UPLOAD_URL, file)
            .then(response => response.data);
    };
    DocumentTemplateServices.clearGlobalLogo = () => {
        const clearGlobalLogoRoute = generateRoute('documentTemplates.clearGlobalLogo');
        return client
            .get(clearGlobalLogoRoute)
            .then(response => response.data);
    };
    DocumentTemplateServices.updateGlobalSettings = (settings) => {
        const route = generateRoute('api.documentTemplate.globalSettings.save');
        return client
            .post(route, { settings })
            .then(response => response.data);
    };
    DocumentTemplateServices.getGlobalSettings = () => {
        const route = generateRoute('api.documentTemplate.globalSettings');
        return client
            .get(route)
            .then(response => response.data);
    };
    DocumentTemplateServices.getAvailablePropertiesForTemplate = (instanceKey, ignoreTemplateId) => {
        const availablePropertiesRoute = generateRoute('documentTemplates.availableProperties', {
            instanceKey,
            ignoreTemplateId
        });
        return client
            .get(availablePropertiesRoute)
            .then(response => response.data);
    };
    DocumentTemplateServices.cloneTemplateForProperties = (templateId, propertiesIds, caption) => {
        const cloneTemplateRoute = generateRoute('documentTemplates.cloneForProperties', {
            id: templateId,
            propertiesIds,
            caption
        });
        return client
            .post(cloneTemplateRoute)
            .then(response => response.data);
    };
    DocumentTemplateServices.deleteDocumentTemplate = (templateId) => {
        const deleteTemplateRoute = generateRoute('documentTemplates.deleteMandateTemplate', {
            id: templateId
        });
        return client
            .delete(deleteTemplateRoute)
            .then(response => response.data);
    };
    DocumentTemplateServices.listDocumentTemplates = async (params) => {
        const route = generateRoute('api.documentTemplates.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    DocumentTemplateServices.createDocumentTemplate = async (documentType) => {
        const route = generateRoute('documentTemplate.createv2', { documentType });
        return client
            .post(route)
            .then(response => response.data);
    };
    function getFileName(disposition) {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
        let fileName = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
        }
        else {
            const filenameStart = disposition.toLowerCase().indexOf('filename=');
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart);
                const matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }
        return fileName;
    }
    DocumentTemplateServices.getFileName = getFileName;
    DocumentTemplateServices.downloadPdf = (template, vars) => {
        const route = generateRoute('api.documentTemplate.generatePdf', { template });
        return client
            .post(route, vars, { responseType: 'blob' })
            .then(response => {
            const disposition = response.headers['content-disposition'];
            const fileName = getFileName(disposition);
            return {
                fileName,
                data: response.data
            };
        });
    };
})(DocumentTemplateServices || (DocumentTemplateServices = {}));
export default DocumentTemplateServices;
